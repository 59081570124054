@use "sass:color";
$divider-color: #ffffff27;

$dark-background-color: #161e27;
$dark-text-color: #fff;
$light-background-color: #F8FAFC;
$light-text-color: #000;

$primary: #0070ff;
$on-primary: #fff;
$primary-hover: color.adjust($primary, $lightness: 5%);
$primary-active: color.adjust($primary, $lightness: -3%);

$secondary-dark: #1e2936;
$on-secondary-dark: #aebbc9;
$secondary-dark-hover: #263544;
$secondary-dark-active: $secondary-dark-hover;//color.mix($dark-background-color, #8f8f8f);
$secondary-light: #f1f3f5;
$on-secondary-light: #42576c;
$secondary-light-hover: #e2e7ec;
$secondary-light-active: $secondary-light-hover;//color.mix($light-background-color, #8f8f8f);

$link-color: inherit; // or $primary
