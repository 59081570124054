@use "../../colors";
@mixin neutralizeLinks {
  a[href] {
    @include neutralizeLink();
  }
}
@mixin neutralizeLink {
  color: colors.$link-color;
  text-decoration: none;
  &:hover, &:active {
    text-decoration: underline;
    color: colors.$link-color;
  }
}
.sfu-typography--site-title {
  font-weight: 600;
  font-size: 28px;
  font-style: normal;
  @include neutralizeLinks();
}
.sfu-typography--display {
  font-weight: 600;
  font-size: 36px;
}
.sfu-typography--object-title {
  font-weight: 600;
  font-size: 18px;
}
.sfu-typography--body {
  font-size: 16px;
  @include neutralizeLinks();
}
.sfu-typography--hint {
  font-size: 14px;
  opacity: 0.7;
  @include neutralizeLinks();
}
.sfu-typography--caption {
  font-size: 12px;
  opacity: 0.7;
  font-weight: 500;
  @include neutralizeLinks();
}
