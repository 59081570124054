@use "colors";
.sfu-root {
  display: flex;
  flex-direction: row;
}
.sfu-layout--flex {
  flex-grow: 1;
}
.sfu-layout--fixed-width {
  width: 520px;
}
@media screen and (max-width: 520px) {
  .sfu-layout--flex.sfu-layout--only-large {
    flex-grow: 0;
  }
}

.sfu-site-header {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--background, colors.$dark-background-color);
  z-index: 100;
  //margin: 8px 0;
  &>* {
    margin: 0 16px;
    &:first-child {
      padding-top: 8px;
    }
    &:last-child {
      padding-bottom: 8px;
    }
  }
  display: block;
  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    $distance: 32px;
    background-image: linear-gradient(to right, #ffffff00 0px, colors.$divider-color $distance, colors.$divider-color calc(100% - $distance), #ffffff00 100%);
  }
}

.sfu-empty-state--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 2px 24px;
  &>* {
    margin: 4px 0;
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 8px;
    }
  }
}
.pad-bottom-a-lot {
  margin-bottom: 24px;
}
.pad-top-a-lot {
  margin-top: 24px;
}
.pad-top-a-little {
  margin-top: 8px;
}
.sfu-site-header.linkable {
  cursor: pointer;
  &:hover {
    background: var(--secondary, colors.$secondary-dark);
  }
}

.sfu-feed-button-row {
  display: flex;
  flex-direction: row-reverse;
}
.sfu-feed-button {
  display: inline-block;
  margin-right: 8px;
  margin-top: -4px;
  margin-bottom: 8px;
}

.sfu-error {
  border: 2px solid #ff0000;
  margin: 12px;
  padding: 16px;
  padding-inline-start: 48px;
  position: relative;
  &::before {
    //color: #ff0000;
    // The below is a really odd hack to get the symbolic icon colored.
    // If it isn't supported, the icon will be uncolored.
    // If @supports isn't supported, it just uses an emoji instead.
    content: "⚠️";
    @supports (mask-image: url()) {
      content: '';
      background-color: #ff0000;
      mask-image: url("./icons/warning_stroke2_corner0_rounded.svg");
    }
    @supports not (mask-image: url()) {
      content: url("./icons/warning_stroke2_corner0_rounded.svg");
      @media (prefers-color-scheme: dark) {
        filter: invert(1);
      }
    }
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    margin: 12px 16px;
  }
}
