@use "../../colors";
@use "sass:color";

.sfu-skyfeed-block, a.sfu-link-tile.sfu-skyfeed-block {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  position: relative;
  .thread {
    flex-shrink: 0;
    left: 24px;
    width: 4px;
    background-color: var(--secondary, colors.$secondary-dark);
    height: 100%;
    display: flex;
    position: absolute;
    .bauble {
      color: var(--on-secondary, colors.$on-secondary-dark);
      background-color: var(--secondary, colors.$secondary-dark);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: -14px;
      height: 32px;
      width: 32px;
      &>svg.larger:first-child {
        width: 28px;
        height: 28px;
        margin: 2px;
      }
      &>svg.smaller:first-child {
        width: 22px;
        height: 22px;
        margin: 5px;
      }
      &>:first-child {
        width: 24px;
        height: 24px;
        margin: 4px;
      }
      &.error {
        color: #ff0000;
      }
      &[data-tooltip]:hover::after {
        position: absolute;
        display: block;
        left: calc(100% + 6px);
        top: 0;
        z-index: 300;
        content: attr(data-tooltip);
        padding: 8px;
        border-radius: 8px;
        color: #fff;
        background-color: #000000c0;
      }
    }
  }
  &:last-child .thread {
    height: 16px;
  }
  &:first-child .thread {
    height: calc(100% - 8px);
    margin-top: 8px;
    .bauble {
      top: 0;
    }
  }
  .thread-spacer {
    width: 48px;
    display: block;
    flex-shrink: 0;
  }
  &>:last-child {
    width: 100%;
    flex-grow: 1;
  }
  &>p:last-child {
    margin: 16px 8px;
  }
  &>.pad-hint:last-child {
    margin-top: 8px;
    &>:first-child {
      margin-left: 8px;
      padding-bottom: 16px;
      line-height: 2em;
    }
  }
}
.sfu-skyfeed-block:target .thread .bauble {
  color: colors.$primary;
  outline: 2px solid colors.$primary;
}

.sfu-skyfeed-block_stash {
  margin-bottom: 16px;
}
.sfu-skyfeed-block_stash .thread {
  height: 16px;
}
.sfu-skyfeed-block_stash+* .thread {
  height: calc(100% - 8px);
  margin-top: 8px;
  .bauble {
    top: 0;
  }
}

:is(.sfu-skyfeed-block_stash, .sfu-skyfeed-block_pop) .shrink {
  width: auto;
  height: 50%;
  margin: 2px;
  flex-grow: 0;
}
:is(.sfu-skyfeed-block_stash, .sfu-skyfeed-block_pop) p:has(+.shrink) {
  width: 100%;
  flex-grow: 1;
  padding: 16px 8px;
}

.sfu-skyfeed-regex {
  margin-left: 8px;
  margin-bottom: 8px;
  &>p:first-child {
    margin: 10px 8px;
    margin-left: 0;
  }
  .sfu-skyfeed-regex--match-tester.invalid {
    border-color: #f00;
    .icon.light-up {
      color: #f00;
    }
  }
}

textarea.style-me {
  width: 100%;
  height: fit-content;
  text-wrap: nowrap;
  background: transparent;
  color: inherit;
  border: none;
  resize: none;
}

.sfu-skyfeed-block_firehose {
  background: var(--secondary, colors.$secondary-dark);
  color: var(--on-secondary, colors.$on-secondary-dark);
  border-radius: 8px;
}

.sfu-skyfeed-block--start {
  color: var(--secondary, colors.$secondary-dark);
  margin: -4px 10px;
}
.sfu-skyfeed-block--end {
  &>svg {
    color: var(--secondary, colors.$secondary-dark);
  }
  &>p {
    display: inline;
    padding-left: 14px;
  }
  margin: -4px 10px;
}

.pad-tags {
  margin: 8px;
}

.sfu-skyfeed-block--tags-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
  .tag {
    @at-root .sfu-skyfeed-block--tags-row a.tag {
      text-decoration: none;
    }
    color: var(--on-secondary, colors.$on-secondary-dark);
    border-radius: 24px;
    padding: 8px;
    background: var(--secondary, colors.$secondary-dark);
    &:hover {
      background: var(--secondary-hover, colors.$secondary-dark-hover);
    }
  }
}

.bluesky-embed a.fallback {
  margin: 16px 8px;
  color: colors.$primary;
  display: block;
  &:not(:hover) {
    text-decoration-color: color.change(colors.$primary, $alpha: 40%);
  }
}
.sfu-skyfeed-block .bluesky-embed:has(iframe) {
  margin-left: 8px;
}
