@use "sass:color";
@use "../../colors";
@use "../typography/typography";

.sfu-button {
  padding: 13px 20px;
  &.small {
    padding: 9px 12px;
    font-size: 14px;
  }
  font-weight: 600;
  font-size: 16px;
  background: colors.$secondary-dark;
  border: none;
  border-radius: 999px;
  color: #ffffff;
  &.sfu-button--primary {
    background: colors.$primary;
    color: colors.$on-primary;
    &:hover {
      background: color.adjust(colors.$primary, $lightness: 5%);
    }
    &:active {
      background: color.adjust(colors.$primary, $lightness: -3%);
    }
  }
  &:hover {
    background: colors.$secondary-dark-hover;
  }
  &:active {
    background: colors.$secondary-dark-active;
  }
  @media (prefers-color-scheme: light) {
    background: colors.$secondary-light;
    &:hover {
      background: colors.$secondary-light-hover;
    }
    &:active {
      background: colors.$secondary-light-active;
    }
  }
}
a.sfu-button {
  @include typography.neutralizeLink();
  &:hover {
    text-decoration: inherit;
  }
}
