@use "../../colors";
.sfu-bluesky-object--header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  padding-bottom: 4px;
  .sfu-bluesky-object--header--text {
    margin: 8px 4px;
  }
  .sfu-bluesky-object--header--avatar {
    width: 56px;
    height: 56px;
    border: 1px #7f7f7f2f;
    border-radius: 12px;
  }
}
.sfu-bluesky-object--description {
  padding: 8px;
  padding-top: 4px;
  margin-bottom: 8px;
  p {
    margin: 0;
  }
}
.sfu-bluesky-object--primary {
  .sfu-bluesky-object--header--avatar {
    width: 72px;
    height: 72px;
  }
}
.sfu-bluesky-object--header--avatar_fallback {
  background-color: colors.$primary;
  background-image: url("./feed-fallback.svg");
  background-size: 100% 100%;
}
.sfu-bluesky-label-tile--avatar_fallback {
  background-color: colors.$primary;
  background-image: url("./feed-fallback.svg");
  background-size: 100% 100%;
}

.sfu-bluesky-label-tile {
  padding: 6px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  vertical-align: middle;
  position: relative;
  &[data-tooltip]:not([data-tooltip=""]).tooltip-below:hover::after {
    position: absolute;
    display: block;
    left: 0;
    top: calc(100% + 4px);
    z-index: 300;
    content: attr(data-tooltip);
    padding: 8px;
    border-radius: 8px;
    color: #fff;
    background-color: #000000c0;
  }
  .sfu-bluesky-label-tile--avatar {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
  .the-label {
    padding: 4px;
    display: block;
    flex-grow: 1;
  }
}
