@use "colors";
@use 'sass:color';
body {
  margin: 0;
  font-family: 'Inter Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: colors.$dark-background-color;
  color: colors.$dark-text-color;
}
:root {
  --secondary: #{colors.$secondary-dark};
  --secondary-hover: #{colors.$secondary-dark-hover};
  --secondary-active: #{colors.$secondary-dark-active};
  --on-secondary: #{colors.$on-secondary-dark};
  --background: #{colors.$dark-background-color};
  --foreground: #{colors.$dark-text-color};
}
@media (prefers-color-scheme: light) {
  body {
    background: colors.$light-background-color;
    color: colors.$light-text-color;
  }
  :root {
    --secondary: #{colors.$secondary-light};
    --secondary-hover: #{colors.$secondary-light-hover};
    --secondary-active: #{colors.$secondary-light-active};
    --on-secondary: #{colors.$on-secondary-light};
    --background: #{colors.$light-background-color};
    --foreground: #{colors.$light-text-color};
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-loading-skeleton {
  --base-color: #{color.adjust(colors.$dark-background-color, $lightness: 3%)};
  --highlight-color: #{color.adjust(colors.$dark-background-color, $lightness: 5%)};
  --animation-duration: 0;
  @media (prefers-color-scheme: light) {
    --base-color: #{color.adjust(colors.$dark-background-color, $lightness: -3%)};
    --highlight-color: #{color.adjust(colors.$dark-background-color, $lightness: -5%)};
  }
}

a.sfu-link-tile {
  display: block;
  text-decoration: inherit;
  color: inherit;
  &:hover {
    background-color: var(--secondary, colors.$secondary-dark);
  }
}
