@use "../../colors";

.sfu-input-text--wrapper {
  border-radius: 8px;
  background: var(--secondary, colors.$secondary-dark);
  color: var(--on-secondary, colors.$on-secondary-dark);
  display: flex;
  flex-direction: row;
  min-width: 180px;
  border: 2px solid var(--secondary, colors.$secondary-dark);
  &::placeholder {
    color: var(--on-secondary, colors.$on-secondary-dark);
    opacity: 70%;
  }
  &:hover:where(:not(:has(input.sfu-input-text--inner:disabled))) {
    border-color: colors.$divider-color;
  }
  &:focus, &:focus-within {
    border-color: colors.$primary;
    .light-up {
      color: colors.$primary;
    }
  }
  .icon {
    color: inherit;
    padding: 8px;
    margin-left: 8px;
    width: 24px;
  }
  input.sfu-input-text--inner {
    font-size: inherit;
    padding: 12px 16px;
    background: transparent;
    border: none;
    color: inherit;
    flex-grow: 1;
  }
  .icon+.sfu-input-text--inner {
    padding-left: 4px;
  }
}
