@use "../../colors";

.sfu-content-warning {
  .show, .hide {
    display: none;
  }
  &[open] .hide {
    display: initial;
  }
  &:not([open]) .show {
    display: initial;
  }
  &>summary:first-child {
    background: var(--secondary, colors.$secondary-dark);
    color: var(--on-secondary, colors.$on-secondary-dark);
    border-radius: 8px;
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    cursor: pointer;
    &:hover {
      background: var(--secondary-hover, colors.$secondary-dark-hover);
    }
    .icon {
      height: 20px;
      width: 20px;
    }
    .sfu-typography--caption:is(.show, .hide) {
      user-select: none;
      color: inherit;
      opacity: 100%;
      font-weight: 600;
      font-size: 16px;
    }
    &>:nth-child(2) {
      flex-grow: 1;
    }
    p {
      padding: 1px 0;
      &.sfu-typography--hint {
        user-select: none;
        font-weight: 500;
        opacity: 100%;
        color: inherit;
      }
    }
  }
}
